import React, { useState, useEffect } from 'react';
import { Table, Form,Col, Row } from 'react-bootstrap';
import "./TableComponent.css"
const TableComponent = ({ data }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filterCategory, setFilterCategory] = useState('');
  const [categories, setCategories] = useState([]);
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    // Extract categories from data
    const uniqueCategories = [...new Set(data.map(item => item["Sous Descipline"]))];
    const listKeys = Object.keys(data[0])
    setCategories(uniqueCategories);
    setKeys(listKeys)
    console.log(data)
  }, [data]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  
  

  const handleCategoryChange = (event) => {
    setFilterCategory(event.target.value);
  };

  const filteredData = data.filter((item) => {
    // Filter based on category
    if (filterCategory && item["Sous Descipline"] !== filterCategory) {
      return false;
    }

    // Filter based on search term
    if (searchTerm && !item.Titre.toLowerCase().includes(searchTerm.toLowerCase())) {
      return false;
    }

    return true;
  });

  return (
    <div>
      <Form className='container mt-3' >
      <Row className="g-4">
        
        <Col md>
        <Form.Group>
            <Row>
            <Col md>
          <Form.Label>Category:</Form.Label>
          </Col>
          <Col md>
          <Form.Control as="select" value={filterCategory} onChange={handleCategoryChange}>
            <option value="">All</option>
            {categories.map((category) => (
              <option key={category} value={category}>{category}</option>
            ))}
          </Form.Control>
          </Col>
        </Row>
        </Form.Group>
        </Col>
        <Col md>
        <Form.Group>
          <Form.Control type="text" placeholder='Resarch' value={searchTerm} onChange={handleSearchChange} />
        </Form.Group>
        </Col>
        </Row>
      </Form>
      <div class="container table-responsive py-5">
      <Table striped bordered>
        <thead className="thead-dark">
          <tr>
            {keys.map((key)=>{
              return <th scope="col">{key}</th>
            })}
            
            
          </tr>
        </thead>
        <tbody>
          {filteredData.map((item) => (
            <tr key={item.id}>
                {keys.map((key)=>{
                  return <td>{item[key]}</td> 
                })}
            </tr>
          ))}
        </tbody>
      </Table>
      </div>
    </div>
  );
};

export default TableComponent;
