import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import TableComponent from './components/TableComponent';
import { Tab, Tabs } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'

const App = () => {

    const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const sheetData = [
        { sheetName: 'Iqraa', sheetId: '1189456826' },
        { sheetName: "Travaux d'Impression", sheetId: '2088075917' },
        { sheetName: 'Diplôme', sheetId: '1100463589' },
        { sheetName: 'Technologie', sheetId: '1122195084' },
        { sheetName: 'Sciences exactes de la terre', sheetId: '538112436' },
        { sheetName: 'Sciences medicales', sheetId: '133760597' },
        { sheetName: 'Sciences sociales', sheetId: '1055501069' },
        { sheetName: 'Généralités', sheetId: '787990634' },
        { sheetName: 'Autre', sheetId: '993869053' }
      ]; // Replace with your sheet names and IDs
      const fetchedData = {};

      for (const { sheetName, sheetId } of sheetData) {
        const url = `https://docs.google.com/spreadsheets/d/1Q9AEuykerHv4Jemzt-FTLDYloWvhk0ig/export?format=csv&gid=${sheetId}`;
        const response = await fetch(url);
        const csvData = await response.text();
        const parsedData = Papa.parse(csvData, { header: true }).data;

        fetchedData[sheetName] = parsedData;
      }

      setData(fetchedData);
    };

    fetchData();
  }, []);

  const [activeTab, setActiveTab] = useState();

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
        <div className="text-center mt-4 mb-4">
        <a href='https://docs.google.com/spreadsheets/d/1Q9AEuykerHv4Jemzt-FTLDYloWvhk0ig/' 
           > 
            Pour télécharger le catalogue au format Excel, cliquez ici
        </a></div>
      <h1 className="text-center mt-4 mb-4">Catalogue de L'office des Publications Universitaire</h1>
      <Tabs activeKey={activeTab} onSelect={handleTabSelect} className='custom-tabs'>
        {Object.entries(data).map(([sheetName, sheetData]) => (

            <Tab eventKey={sheetName} title={sheetName} >
              <p>{sheetName==="Travaux d'Impression" ? "NB: que ce sont les services les plus demandés, mais nous effectuons différents types d'impression" : "" }</p>
           
              <TableComponent data={sheetData} />
            </Tab>
        ))}
      </Tabs>
    </div>
  );
};

export default App;
